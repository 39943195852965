<template>
  <div>
    <h2 class="text-xl font-bold">
      General
    </h2>
    <div class="my-7 grid grid-flow-row gap-1">
      <NuxtLink to="/about" class="prettyLink max-w-fit">
        About me
      </NuxtLink>
      <NuxtLink to="/talks-webinars" class="prettyLink max-w-fit">
        Talks & webinars
      </NuxtLink>
      <NuxtLink to="/private-workshops" class="prettyLink max-w-fit">
        Private workshops
      </NuxtLink>
      <NuxtLink to="/company-consulting" class="prettyLink max-w-fit">
        Company consulting
      </NuxtLink>
      <NuxtLink to="/individual-tutoring" class="prettyLink max-w-fit">
        Individual tutoring
      </NuxtLink>
      <NuxtLink to="/content-authoring" class="prettyLink max-w-fit">
        Video & blog authoring
      </NuxtLink>
    </div>
  </div>
</template>
